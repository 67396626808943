import {
  Box,
  CardInline,
  Container,
  Heading,
  Helmet,
  IconSheets,
  Layout,
  LinearBorder,
  List,
  Panel,
  ResourcesTile,
  Row,
  Section,
  Theme
} from "@life-without-barriers/react-components"
import {
  ContentNodes,
  PageResources,
  Resource,
  Site,
  firstNode,
  firstNodes
} from "@life-without-barriers/gatsby-common"
import { graphql, withPrefix } from "gatsby"

import AboriginalAndTorresStraitIslanderPeoplesBanner from "../../../components/social-policy/AboriginalAndTorresStraitIslanderPeoplesBanner"
import { IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import firstTimeLine from "../../../images/social-policy/aboriginal-and-torres-strait-islander-peoples/2009-timeline-circle.png"
import fourthTimeLine from "../../../images/social-policy/aboriginal-and-torres-strait-islander-peoples/2020-timeline-circle.png"
import { renderFeaturedResources } from "../../../components/social-policy/contentRenderer"
import secondTimeLine from "../../../images/social-policy/aboriginal-and-torres-strait-islander-peoples/2012-timeline-circle.png"
import thirdTimeLine from "../../../images/social-policy/aboriginal-and-torres-strait-islander-peoples/2016-timeline-circle.png"

const { aboriginalAndTorresStraitIslanderPeoplesThemeFull } = Theme

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    openGraphImage: IGatsbyImageData
    pageResources: PageResources
    rapResource: ContentNodes<Resource>
    rapSummaryResource: ContentNodes<Resource>
    ourJourneyResource: ContentNodes<Resource>
    artworkStoryResource: ContentNodes<Resource>
  }
}

const OurReconciliationJourney = ({
  location,
  data: {
    site: { siteMetadata },
    openGraphImage,
    pageResources,
    rapResource,
    rapSummaryResource,
    ourJourneyResource,
    artworkStoryResource
  }
}: Props) => {
  const ourJourney = firstNode(ourJourneyResource)
  return (
    <div>
      <Helmet
        title={`Our reconciliation journey - We support Reconciliation | ${siteMetadata.title}`}
        description="We know that it is vital to work with and ensure respect for cultural connectedness for our clients and all Aboriginal and Torres Strait Islander people."
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        image={openGraphImage}
      />
      <Layout theme={aboriginalAndTorresStraitIslanderPeoplesThemeFull}>
        <AboriginalAndTorresStraitIslanderPeoplesBanner title="Our Reconciliation journey" />
        <Container>
          <Row className="flex-wrap">
            <Box className="layout-readable pb4">
              <Heading size={2}>Our Reconciliation journey: 2009-2023</Heading>
              <p>
                Reconciliation is an ongoing journey that requires our constant
                learning and commitment. Life Without Barriers’ commits to
                Reconciliation being central to our activities as an
                organisation and influencing Reconciliation in the broader
                communities in which we live and work.
              </p>
              <p>
                Life Without Barriers established our relationship with
                Reconciliation Australia in 2008 and launched our first
                Reconciliation Action Plan (RAP) in 2009.
              </p>
            </Box>
            <Panel background="bg-lwb-grey-xxx-light">
              <Row className="flex-wrap">
                <Box className="flex w-100 w-third-l items-center justify-center pt2">
                  <img
                    alt="2020 timeline"
                    className="tc"
                    src={withPrefix(fourthTimeLine)}
                  />
                </Box>
                <Box className="w-100 w-two-thirds-l">
                  <List
                    items={[
                      `Reconciliation Action Plan - Elevate 2022-2025.`,
                      `In 2022, Reconciliation Australia affirmed Life Without
                    Barriers with an Elevate RAP.`,
                      `From 2020, Life Without Barriers invested time exploring and
                    consulting with key stakeholders and partners on the
                    commitments Life Without Barriers could embrace that would
                    have the greatest impact for the future. The consultation
                    and engagement aided in the development of the key actions
                    and deliverables reflected in the Elevate RAP.`
                    ]}
                    isOrdered={false}
                  />
                </Box>
              </Row>
            </Panel>
          </Row>
          <Row className="flex-wrap pb4">
            <Box className="w-100 w-50-m w-third-l pt5">
              <img alt="2016 timeline" src={withPrefix(thirdTimeLine)} />
              <List
                items={[
                  `Reconciliation Action Plan – Stretch 2016-2019.`,
                  `The main objective of the RAP was to ensure that Aboriginal and
                Torres Strait Islander cultures were core considerations in our
                workplace, program development and delivery. The RAP provided
                real actions with positive impact and achievable and measurable
                outcomes for the people and communities we work with and ensures
                the voices of Aboriginal and Torres Strait Islander people are
                heard throughout our organisation.`
                ]}
                isOrdered={false}
              />
            </Box>
            <Box className="w-100 w-50-m w-third-l pt5">
              <img alt="2012 timeline" src={withPrefix(secondTimeLine)} />
              <List
                items={[
                  `Reconciliation Action Plan - Innovate 2012–2015.`,
                  `We continued to work with Aboriginal and Torres Strait Islander
                people, staff and communities to ensure their voice is heard and
                programs and services continued to evolve to meet their needs.`,
                  `Our Innovate RAP allowed our organisation to gain a deeper
                understanding of our sphere of influence and establish the best
                approach to advance Reconciliation.`
                ]}
                isOrdered={false}
              />
            </Box>
            <Box className="w-100 w-50-m w-third-l pt5">
              <img alt="2009 timeline" src={withPrefix(firstTimeLine)} />
              <List
                items={[
                  `Reconciliation Action Plan - Reflect 2009-2011.`,
                  `We established our first RAP in 2009, which set out steps that
                prepared our organisation for Reconciliation initiatives in our
                future RAPs.`,
                  `This Reflect RAP committed to scoping and developing
                relationships with Aboriginal and Torres Strait Islander
                stakeholders, deciding on our vision for Reconciliation and
                exploring our sphere of influence.`
                ]}
                isOrdered={false}
              />
            </Box>
          </Row>
          <Box>
            {ourJourney && (
              <CardInline
                title={ourJourney.title}
                image={ourJourney.image.gatsbyImageData}
                href={ourJourney.pdfAsset?.file.url ?? ourJourney.url ?? ""}
                copy={
                  ourJourney.description.childMarkdownRemark.rawMarkdownBody
                }
                standAlone={true}
              />
            )}
          </Box>
        </Container>
        {renderFeaturedResources({
          title: "Our Elevate RAP - 2022 to 2025",
          resources: firstNodes([
            rapResource,
            rapSummaryResource,
            artworkStoryResource
          ]),
          contentBlock: (
            <p>
              Life Without Barriers is currently implementing its fourth
              Reconciliation Action Plan (RAP), an Elevate RAP. Our Elevate RAP
              guides and directs our commitment and work in supporting
              Reconciliation from 2022 through to 2025.
            </p>
          )
        })}
        <Container>
          <LinearBorder>
            At Life Without Barriers, we believe that Reconciliation must live
            in the hearts and minds of all Australians. As a nation, we need to
            work together to close the gap in life expectancy between Aboriginal
            and Torres Strait Islander people and the rest of the population by
            improving cultural, spiritual, physical and emotional wellbeing.
            Reconciliation is more than a belief for us – it is embedded in all
            of our actions and activities.
          </LinearBorder>
        </Container>
        <Section background="bg-lwb-grey-xxx-light">
          <Container>
            <Row>
              <Box className="w-100">
                <Heading size={2} topMargin="none">
                  Our previous work
                </Heading>
                <ResourcesTile
                  className="mt4 mt4-ns lwb-shadow w-100"
                  name="Resources"
                  icon={
                    <IconSheets
                      height="25"
                      color={
                        aboriginalAndTorresStraitIslanderPeoplesThemeFull.medium
                      }
                    />
                  }
                  resources={
                    pageResources.edges[0].node?.pageDocuments?.map(
                      ({ title, file }) => ({
                        name: title,
                        reference: file.url
                      })
                    ) || []
                  }
                />
              </Box>
            </Row>
          </Container>
        </Section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    openGraphImage: file(relativePath: { regex: "/our-journey-og.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    pageResources: allContentfulPageResources(
      filter: {
        title: {
          eq: "aboriginal-and-torres-strait-islander-people-our-reconciliation-journey"
        }
      }
    ) {
      edges {
        node {
          title
          pageDocuments {
            ...RemoteContentfulFileAsset
          }
        }
      }
    }
    rapResource: allContentfulResource(
      filter: { title: { regex: "/Elevate RAP 2022/i" } }
    ) {
      edges {
        node {
          ...ContentfulResource
        }
      }
    }
    rapSummaryResource: allContentfulResource(
      filter: { title: { regex: "/Elevate RAP Summary 2022/i" } }
    ) {
      edges {
        node {
          ...ContentfulResource
        }
      }
    }
    ourJourneyResource: allContentfulResource(
      filter: {
        title: { eq: "Reconciliation is at the heart of our organisation" }
      }
    ) {
      edges {
        node {
          ...ContentfulResource
        }
      }
    }
    artworkStoryResource: allContentfulResource(
      filter: { title: { regex: "/Artwork story/i" } }
    ) {
      edges {
        node {
          ...ContentfulResource
        }
      }
    }
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
  }
`

export default OurReconciliationJourney
