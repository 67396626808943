import { Banner, routesObject } from "@life-without-barriers/react-components"

import { IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"

interface Props {
  title: string
  subTitle?: string
  bannerImage?: IGatsbyImageData
}

const AboriginalAndTorresStraitIslanderPeoplesBanner = ({
  title,
  subTitle,
  bannerImage
}: Props) => (
  <Banner
    title={title}
    subTitle={subTitle}
    breadcrumbs={[
      routesObject.home,
      {
        text: "Social policy",
        title: "Return to the social policy page",
        to: "/social-policy"
      },
      {
        text: "Aboriginal and Torres Strait Islander peoples",
        title:
          "Return to the Aboriginal and Torres Strait Islander peoples page",
        to: "/social-policy/aboriginal-and-torres-strait-islander-peoples"
      }
    ]}
    image={bannerImage}
    share
  />
)

export default AboriginalAndTorresStraitIslanderPeoplesBanner
